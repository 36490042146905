<script setup>
import { onMounted, computed, provide } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import InterviewProcess from "@/components/ui/modelComponents/interview/InterviewProcess.vue";

import {
  FETCHSINGLEAPPRAISALACTION,
  SINGLE_APPRAISAL_GETTER,
} from "@/store/modules/MA/appraisals/constants.js";

provide("saveAnswer", "SAVEAPPRAISALANSWERACTION");
provide("vuexModule", "Appraisals");
provide("type", "appraisal");

const store = useStore();
const route = useRoute();
onMounted(async () => {
  await store.dispatch(`Appraisals/${FETCHSINGLEAPPRAISALACTION}`, {
    appraisal: route.params?.id || 0,
  });
});

const lastHeads = [
  {
    title: "Goals & Objectives",
    type: "AppraisalComponent",
    name: "AppraisalComponent",
  },
  {
    title: "Re-Schedule",
    type: "ReSchedule",
  },
  {
    title: "Sign Off",
    type: "AppraisalFinalStep",
  },
];
// const groupBy = (arr, key) => {
//   const initialValue = {};
//   return arr?.reduce((acc, cval) => {
//     const myAttribute = cval[key];
//     acc[myAttribute] = [...(acc[myAttribute] || []), cval];
//     return acc;
//   }, initialValue);
// };
// const headsArray = ["Pre-Appraisal", "Appraisal"];

const interviewSteps = computed(() => {
  const data = store.getters[`Appraisals/${SINGLE_APPRAISAL_GETTER}`];
  if (data) {
    // const groupedQuestion =
    //   data && groupBy(data?.["appraisal_policies"][0]?.questions, "head");
    console.log("questions here: " + data.value);
    const steps = [];
    steps.push({
      title: "Appraisal",
      questions: data?.appraisal_policies[0]?.questions,
    });
    // groupedQuestion &&
    //   Object.keys(groupedQuestion).map((item) => {
    //     steps.push({
    //       title: headsArray[item - 1],
    //       questions: groupedQuestion[item],
    //     });
    //   });
    lastHeads.map((item) => {
      steps.push(item);
    });
    steps.unshift({
      title: "Employee Details",
      type: "profileCard",
    });
    return steps;
  }
  return [];
});
</script>
<template>
  <div class="p-5 h-full flex flex-row md:flex-col flex-grow">
    <InterviewProcess :interviewSteps="interviewSteps"></InterviewProcess>
  </div>
</template>
