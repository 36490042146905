<script setup>
import { inject, ref, defineProps } from "vue";
import { useRouter } from "vue-router";
import Timeline from "@/components/ui/baseComponents/timeline.vue";
import InterviewRunner from "@/pages/RE/interviewOffers/InterviewRunner.vue";
import signaturepad from "@/components/ui/baseComponents/signaturepad.vue";
import { useStore } from "vuex";
import Swal from "sweetalert2";

const store = useStore();
const router = useRouter();
const props = defineProps({
  interviewSteps: {
    type: [Array, Object],
    required: true,
  },
});
const emitter = inject("emitter");

const activeStep = ref(0);
const showModal = ref(false);
var appraisalObject = ref({});

const completeInterview = async () => {
  // showModal.value = true;
  console.log("asdfadsfasd: ", appraisalObject.value);
  setTimeout(function () {}, 2000);
  await store.dispatch("Appraisals/concludeAppraisal", appraisalObject.value);
  Swal.fire({
    title: "Done",
    text: "Operation Completed Successfully",
    icon: "success",
  });
  router.push({ name: "ma-appraisals" });
};

const concludeAppraisalFunc = (value) => {
  appraisalObject.value = value;
};

const saveSignature = (e) => {
  emitter.emit("onSaveSignature", e);
  showModal.value = false;
  Swal.fire({
    title: "Done",
    text: "successfully Completed",
    icon: "success",
  });
};
</script>

<template>
  <div class="md:mx-4 md:p-4 h-full md:h-auto w-auto">
    <Timeline
      :totalSteps="props.interviewSteps"
      :activeStep="activeStep"
      :responsive="true"
    />
  </div>
  <template v-if="props.interviewSteps?.[activeStep]">
    <InterviewRunner
      :currentStep="props.interviewSteps[activeStep]"
      :activeStep="activeStep"
      :interviewSteps="props.interviewSteps"
      @gotoPreviousStep="activeStep--"
      @gotoNextStep="activeStep++"
      @completeInterview="completeInterview"
      @concludeAppraisal="concludeAppraisalFunc"
    />
  </template>
  <base-modal v-model:show="showModal" :showCancel="false" :showConfirm="false">
    <template #title>Completed Interveiw </template>
    <template #content>
      <signaturepad @saveSignature="saveSignature">
        <template #saveButton>
          <button
            class="
              bg-teal
              border border-gray-400
              font-medium
              mt-3
              px-3
              py-3
              rounded-md
              text-white
            "
          >
            save Signature
          </button>
        </template>
      </signaturepad>
    </template>
  </base-modal>
</template>